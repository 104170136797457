import styled from "styled-components"

const HEIGHT = '1200px'

export const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: ${HEIGHT};
    align-items: center;
    justify-content: center;
`

export const StyledDivFill = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    background-color: ${props => props.backgroundColor || 'white'}
`

export const StyledTitle = styled.h1`
    margin: 80px;
    color: red;
    font-family: consolas;
`

export const StyledTitleCenter = styled.h1`
    display: flex;
    flex-direction: column;
    margin: ${props => props.margin || '100px'};
    margin-top: ${props => props.marginTop || 'None'};
    color: ${props => props.color || 'black'};
    -webkit-text-stroke-width: ${props => props.strokeWidth || 'None'};
    -webkit-text-stroke-color: ${props => props.strokeColor || 'None'};
    font-size: ${props => props.fontSize || '1em'};
    font-family: consolas;
    align-items: center;
    justify-content: center;
`

export const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    list-style-type: ${props => props.listStyleType}
`

export const StyledImage = styled.img`
    src: ${props => props.src}
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
    width: ${props => props.width}
    height: ${props => props.height}
`

export const StyledRow = styled.div`
    className: py-2 text-center footer-style;
    display: flex;
    flex-direction: row;
    margin: ${props => props.margin || "5px"};
    margin-top: ${props => props.marginTop || 'None'};
    margin-bottom: ${props => props.marginBot || 'None'};
    font-weight: ${props => props.fontWeight || "normal"};
    color: ${props => props.color || 'black'};
    font-family: consolas;
    align-items: center;
    justify-content: center;
`

export const StyledParagraphCenter = styled.p`
    display: flex;
    flex-direction:row;
    font-weight: ${props => props.fontWeight || "normal"};
    color: ${props => props.color || 'black'};
    font-family: consolas;
    justify-content: center;
`