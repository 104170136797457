import React from 'react'
import { Parallax } from 'react-parallax'
import Intro from './sections/intro.js'
import Stuff from './sections/stuff.js'
import Footer from './sections/footer.js'
import Links from './sections/links.js'

import topImg from "../assets/jean-philippe-delberghe-75xPHEQBmvA-unsplash.jpg"
import midImg from "../assets/public-domain-images-free-stock-photos-bicycle-bike-black-and-white.jpeg"
import botImg from "../assets/public-domain-images-free-stock-photos-001.jpg"

const App = ({links}) => {
    return (
        <div>
            <Parallax blur={{ min:-15, max: 15}} bgImage={topImg} strength={500}>
                <Intro />
            </Parallax>
            <Parallax blur={{ min:-30, max: 32}} bgImage={midImg} strength={500}>
                <Stuff links={links}/>
            </Parallax>
            <Parallax blur={{ min:-15, max: 15}} bgImage={botImg} strength={500}>
                <Links />
                <a style={{float: "right", margin: "1.25em", color: "white"}} class="btn bg-dark" href="#top">&#8593;</a>
            </Parallax>
        </div>
    )
}

export default App
