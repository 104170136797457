import React from "react"
import Container from "react-bootstrap/Container"
import { StyledTitleCenter } from "../../../styled-css/StyledListElements.jsx"

const Links = () => {
    return(
        <div id="intro">
            <div className="intro">
                <div style={{ display: 'flex', flexDirection: 'column', height: '825px' }}>
                    <StyledTitleCenter color='black' fontSize='3em'>Take a look around, thank you.</StyledTitleCenter>
                </div>
            </div>            
        </div>
    )
}

export default Links