import React from "react"
import Container from "react-bootstrap/Container"
import { StyledTitleCenter, StyledList, StyledDiv, StyledImage, StyledRow, StyledGridRow } from "../../../styled-css/StyledListElements.jsx"

import parallaxGif from "../../assets/output.gif"
import todoappImg from "../../assets/django_todo_app.png"
import bddImg from "../../assets/bdd_features_example.png"
import reactAppImg from "../../assets/quiz_example.png"
import discordImg from "../../assets/discord_tests.png"
import projectImg from "../../assets/schoo_group_project.png"
import Links from "./links.js"

const IMG_HEIGHT = "250em"
const IMG_WIDTH = "auto"

const GITHUB_LINK_1 = "https://github.com/mikeclaros"
const GITHUB_LINK_2 = "https://github.com/shibachurro"

const RowMarginTop = {marginTop: "6em"}

const Stuff = ({links}) => {
    const TimelineComponent = ({text, img, imgLink, reversed, marginTop}) => {

        let timeLineMarginTop = (marginTop)?{marginTop: marginTop}:RowMarginTop

        const LinkedComponent = ({link, img}) => {
            return (
                <a href={link} className="col-md-8 align-items-center text-center">
                    <img id={img.split('/').at(-1).split(".")[0].replaceAll("_", "-")} src={img} height={IMG_HEIGHT} width={IMG_WIDTH}></img>
                </a>
            )
        }

        const ImageComponent = ({img}) => {
            return (
                <div class="col-md-8 align-items-center text-center">
                    <img src={img} height={IMG_HEIGHT} width={IMG_WIDTH}></img>
                </div>
            )
        }

        const TextLineComponent = ({text}) => {
            return (
                <div className="col-md-4 justify-content-center text-center">
                    <span style={{fontSize: "1.75em", color: "black"}}>{text}</span>
                </div>
            )
        }
        const TimeLineImageComponent = ({img, imgLink}) => {
            if(imgLink){
                return <LinkedComponent link={imgLink} img={img} />
            } else {
                return <ImageComponent img={img} />
            }
        }


        const BundleComp = () => {
            return (
                <div className="row align-items-center" style={timeLineMarginTop}>
                    <TextLineComponent text={text} />
                    <TimeLineImageComponent img={img} imgLink={imgLink}/>
                </div>
            )
        }

        const ReversedBundleComp = () => {
            return (
                <div className="row align-items-center" style={timeLineMarginTop}>
                    <TimeLineImageComponent img={img} imgLink={imgLink}/>
                    <TextLineComponent text={text} />
                </div>
            )
        }

        return (reversed)?<ReversedBundleComp/>:<BundleComp />
    }


    return(
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '2100px' , marginTop: '500px'}}>
            <div>
                <Container>
                    <StyledDiv>
                        <StyledTitleCenter marginTop='600px' fontSize='3em'>Project Examples</StyledTitleCenter>
                        <div className="container">
                            <TimelineComponent text={"Todo App made with Django"} img={todoappImg} imgLink={links.todoLink} marginTop={"0px"}/>
                            <TimelineComponent text={"React Parallax Frontend for this site"} img={parallaxGif} reversed={true}/>
                            <TimelineComponent text={"Functional test cases of todo app with gherkin"} imgLink={links.bddLink} img={bddImg}/>
                            <TimelineComponent text={"Small React projects"} img={reactAppImg} reversed={true} imgLink={links.reactLink}/>
                            <TimelineComponent text={"Discord app testing with Jest"} img={discordImg} imgLink={links.discordBotLink}/>
                            <TimelineComponent text={"College senior group project"} img={projectImg} reversed={true} imgLink={links.collegeProjLink}/>
                            <div className="justify-content-center text-center" style={{marginTop: '100px'}}>
                                <a style={{fontSize: "1.30em"}} href={GITHUB_LINK_1}>Github main</a>
                                &nbsp;&nbsp;&middot;&nbsp;&nbsp;<a style={{fontSize: "1.30em"}} href={GITHUB_LINK_2}>Github side stories</a>
                            </div>
                        </div>
                    </StyledDiv>
                </Container>
            </div>
        </div>
    )
}

export default Stuff
