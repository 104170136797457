import React from "react"
import Container from "react-bootstrap/Container"
import { StyledTitleCenter } from "../../../styled-css/StyledListElements.jsx"
const Intro = () => {
    return(
        <div id="intro">
            <div style={{ display: 'flex', flexDirection: 'column', height: '51em' }}>
                <StyledTitleCenter 
                    color='black'
                    fontSize='4em'
                    style={{marginLeft: '.25em'}}
                >
                Hello, <br/>
                </StyledTitleCenter>
                <StyledTitleCenter 
                    color='black'
                    fontSize='4em'
                >
                I'm Mike
                </StyledTitleCenter>
                
            </div>            
        </div>
    )
}

export default Intro